.feedback-form-container {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    padding-top: 20px;
    box-sizing: border-box;
    background-color: rgb(241, 238, 238);
}

.feedback-form-container-card {
    width: 100%;
    max-width: 500px;
    margin-top: 0px;
    /* padding-bottom: 10px; */
    /* margin-bottom: 10px; */
    padding: 5px !important;
    box-sizing: border-box;
}

.feedback-form-header {
    font-size: 14px !important;
    margin: 5px 10px;
    font-weight: 800;
    text-align: left;
    color: #383838;

}

.feedback-form-sub-header {
    font-size: 25px !important;
    margin: 5px 10px 10px 10px;
    /* color: black; */
    color:#2ab7a3;
    font-weight: bold;
    text-align: left;
}

.feedback-form-sub-title {
    font-size: 16px !important;
    margin: 0px 10px;
    color: #383838;
    text-align: left;
}

.loader-container {
    margin-left: 45%;
}

.feedback-form-question-title {
    font-size: 18px;
    font-weight: bold;
    margin: 15px !important;
}

.feedback-card-line-container {
    display: flex !important;
    margin: 15px 0px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    /* padding: 10px; */
}
.feedback-card-line-container-one {
    border-top: 5px solid black;
    width: 45%;
    border-radius: 10px;
    /* margin: 0px 10px;  */
}

.feedback-card-line-container-two {
    border-top: 5px solid grey;
    width: 45%;
    border-radius: 10px;
    /* margin: 0px 10px 0px 0px */
}

.feedback-card-container {
    border-radius: 20px !important;
    height: 485px;
    padding: 5px;
}

.feedback-form-question-container {
    padding: 3px 0px;
    margin: 10px 0px
        /* background-color: red; */
        /* margin-top: 50px; */
        /* font-size: 26px; */
}

.feedback-form-question {
    /* margin: 0px 20px; */
    margin: 10px 50px;
    /* padding: 5px 0px; */
    font-size: 15px;
    /* font-weight: 400; */
    color: rgb(26, 26, 26);
}

.feedback-form-submit {
    border: 1px solid black !important;
    color: rgb(0, 0, 0) !important;
    width: 30% !important;
    border-radius: 8px !important;
    text-transform: inherit !important;
    font-weight: bolder !important;
    box-shadow: none !important;
    /* font-size: 50px !important; */
    padding: 7px 0px !important;
    color: "red"
}

.validation-message{
    display: flex;
    justify-content: center;
    align-items: center;
    color:'red'
}

.feedback-form-submit:hover {
    background-color: #2ab7a3 !important;
    /* color: white !important; */
}

.feedback-form-submit-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 200px; */
}

.feedback-form-submit-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 10px !important; */
}

.feedback-form-submit-button {
    background: #2ab7a3 !important;
    font-size: 18px !important;
    text-transform: inherit !important;
    margin-top: 5px !important;
    font-weight: 550 !important;
    padding: 8px !important;
    color: rgb(0, 0, 0);
    width: 92% !important;
    cursor: pointer !important;
    border-radius: 15px !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.feedback-form-error-message {
    font-size: 14px;
    color: rgb(241, 77, 77);
    /* margin: 1px 80px; */
    /* padding: 1px auto; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-button{
    margin-top: 10px !important;
    background: black !important;
    width:85% !important;
    height:50px !important;
}


@media (max-width: 768px) {
    .feedback-form-container {
        padding: 10px !important;
    }

    .feedback-form-header {
        font-size: 12px !important;
    }

    .feedback-form-sub-header {
        font-size: 23px !important;
    }

    .feedback-form-sub-title {
        font-size: 13px !important;
    }

    .feedback-form-question {
        font-size: 15px;
        font-weight: 500;
        /* margin-bottom: 10px !important; */
        margin: 0px 10px 10px 20px !important;
    }

    .feedback-form-submit {
        /* width: 50px !important; */
        width: 40% !important;
        font-size: 14px !important;
        /* padding: 7px 0px !important; */
    }

    .feedback-form-container-card {
        box-shadow: none !important;
    }

    .feedback-form-submit-button {
        width: 92% !important;
        font-size: 16px;
    }

    .feedback-form-error-message {
        font-size: 12px;
        margin: 1px 20px;
        padding: 2px auto;
    }

    .feedback-form-question-title {
        font-size: 16px;
        font-weight: bold;
        margin: 10px 20px;
    }

    .feedback-form-question-container {
        margin: 10px 0px
    }

    .submit-button{
        width:97% !important;
        height:45px !important;
    }

}