.lithium-city-league-container{
    border-radius: 0px;
    /* margin: 20px 200px 0px 200px */
    margin: 1% 10%
}

.lithium-city-tab{
    padding: 0px !important;
}

@media (max-width: 768px) {
    .lithium-city-league-container {
        margin: 0% 0% !important;
    }
    .lithium-city-league-box-container{
        height: 100vh;
    }
    .MuiBox-root {
        .css-19kzrtu{
            padding: 10px !important;
        }
    }
}