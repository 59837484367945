.thank-you-page-container {
    display: flex;
    height: 100vh; /* Adjust as needed */
    align-items: center;
    justify-content: center;
    /* margin:0px 20px; */
    padding: 0px 20px;
    background-color: rgb(241, 238, 238);
}


.thank-you-page-card {
    width: 70%; /* Adjust according to your design */
    max-width: 400px; /* Optional: Limit maximum width */
    height: auto; /* Allow height to adjust based on content */
    padding: 20px;
    border-radius: 20px !important;
}

.thank-you-page-title{
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    margin-bottom:10px;
}

.thank-you-page-sub-title{
    font-size: 14px;
    text-align: center;
    margin:10px;
    color: #999898;
}

.thank-you-page-link{
    font-size: 14px;
    text-align: center;
    margin-top: 15px;
    color:blue;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
}


.thank-you-page-submit {
    background: black !important;
    font-size: 20px !important;
    padding: 12px !important;
    font-size: 18px !important;
    text-transform: inherit !important;
    width: 100%; /* Make buttons full width */
    margin-bottom: 10px; /* Adjust spacing between buttons */
}

@media (max-width: 768px) {
    .thank-you-page-card {
        width: 90%;
    }
    .thank-you-page-title{
        font-size: 20px;
    }
    .thank-you-page-submit {
        font-size: 16px !important;
    }
}
