.access-denied-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #2ab7a3;
  }
  
  .access-denied-card {
    padding: 20px;
    text-align: center;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .access-denied-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color:rgb(244, 67, 67)
  }
  
  .access-denied-message {
    font-size: 16px;
  }
  