.feed-back-ev-icon{
    width: 100%;
    height: 300px;
    object-fit: contain;
}

/* Home.css */

.content {
    padding-top: 70px; /* Adjust according to header height */
    /* Other styles for content area */
    overflow-y: auto; /* Enable vertical scrolling if needed */
    height: auto; /* Adjust height based on viewport and header height */
  }
  
  @media (max-width: 768px) {
    .content {
        padding-top: 60px; 
}}