.login-page-container {
    display: flex;
    height: 100vh; /* Adjust as needed */
    align-items: center;
    justify-content: center;
    margin:0px 0px;
    background-color: white;
}


.login-page-card {
    width: 40%; /* Adjust according to your design */
    max-width: 500px; /* Optional: Limit maximum width */
    height: auto; /* Allow height to adjust based on content */
    padding: 30px;
}

.feed-back-ev-icon {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
}

.login-page-button {
    background: black !important;
    color:"white";
    font-size: 18px !important;
    text-transform: inherit !important;
    padding: 10px !important;
    font-weight: 550 !important;
    width: 100%; /* Make buttons full width */
    /*margin-bottom: 10px;  Adjust spacing between buttons */
}

.login-page-container-title{
        font-size: 24px;
        text-align: center;
        font-weight: bold;
        margin:30px auto;
}

@media (max-width: 768px) {
    .login-page-card {
        width: 90%;
    }
    .feed-back-ev-icon {
        max-height: 200px;
    }
    .login-page-button {
        font-size: 18px !important;
    }
    .thank-you-page-title{
        font-size: 20px;
    }
}
