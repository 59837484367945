.client-sites-container{
    height: 500px;
}

.client-sites-container-grid {
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-sites-container-image{
  height: 200px;
  width: 180px;
}

.city-league-image {
  height: 150px !important;
  width: 150px !important;
}

.MuiBox-root css-19kzrtu{
  padding: 0px !important;
}

.client-sites-container-title{
  font-size: 35px;
  font-weight: bold;
  color: white;
  text-align: left;
  transform: scale(1.0, 1.3);
  margin-bottom: 2px;
}

.client-sites-container-sub-title{
    font-size: 23px;
    font-weight: bold;
    color: white
}

.client-sites-container-select{
    font-size: 20px;
    padding: 10px 0px;
    /* background-color: aqua; */
    outline: none;
    border:none;
    font-weight: bold;
    outline: none;
}

.client-sites-table-container{
    /* margin-left:15%; */
    display:flex;
    align-items:center;
}

.lithium-car-image{
  height: 230px;
  width: 250px;
  margin-left: 50px;
}

.lithium-car-image-container{
  margin-left: 50px;
}

.client-sites-container-table {
    color: black;
    border-collapse: collapse;
    text-align: center;
    width: 600px;
    /* font-family: bold !important; */
  }

  .client-sites-container-table-row-one{
    height:45px;
    background:#CBC3E3;
    font-weight: bold;
    width: 30px;
  }

  .lithium-car-image-container{
    margin-left: 0px;
    margin-top: 10px;
  }

  .client-sites-container-table-row-two{
    height:45px;
    font-weight: bold;
    background:white;
    text-align:left;
    padding-left:15px;
    border-bottom-right-radius: 40% 100%;
    background: 
    linear-gradient(-70deg, transparent 45px, #ddd 0);
    background: linear-gradient(-30deg, transparent 50px, #ddd 0);
    width: 220px;
  }

  @media (max-width: 768px) {
    .client-sites-container-title {
      font-size: 18px;
      margin-left:4%;
      margin-top:10px;
      /* color:#f8c137; */
    }

    .city-league-image {
      height: 80px !important;
      width: 75px !important;
    }

    .client-sites-container-table {
      width: 350px;
    }

    .client-sites-container-table-row-two{
      height:40px;
      font-weight: bold;
      background:white;
      text-align:left;
      padding-left:5px;
      border-bottom-right-radius: 40% 100%;
      background: 
      linear-gradient(-60deg, transparent 55px, #ddd 0);
      width: 200px;
    }

 

    .client-sites-table-container{
      display: inline;
      align-items: center;
      justify-content: center;
      margin-left:10px;
      margin-top:10px;
  }

  .lithium-car-image{
    text-align: center;
    height: 120px;
    margin-left: 50px;
    /* height: 20px;
    width: 20px; */
  }
    
    .client-sites-container-sub-title {
      font-size: 13px;
      margin-left:4%;
    }

    .client-sites-container-image{
      height: 100px;
      width: 100px;
    }
    
    .client-sites-select-container{
      display:flex;
      align-items:center;
      justify-content:center;
      margin-top:50px;
      margin-right:20px
    }

    .client-sites-container-select {
      font-size: 13px;
      /* padding: 0px; */
      padding: 5px 0px;
      margin: 5px 2px 0px 10px;
    }
    
    .client-sites-container-grid {
      flex-direction: column;
      align-items: stretch;
    }
    
    .client-sites-container-table {
      font-size: 14px;
    }
  }