.city-league-sites-container {
  height: auto; /* Adjust height for better mobile responsiveness */
  padding: 0px 10px; /* Add padding for better spacing */
}

.city-league-sp-hiring-grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: teal;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.city-league-image {
  height: 120px;
  width: 120px;
}

.city-league-sites-title {
  font-size: 35px;
  font-weight: bold;
  color: white;
  text-align: left;
  transform: scale(1.0, 1.3);
  margin-bottom: 2px;
}

.city-league-sites-sub-title {
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: left;
  transform: scale(1.0, 1.3);
}

.city-league-sites-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  overflow-x: auto; /* Allow horizontal scrolling on mobile */
}

.city-league-sites-table {
  color: black;
  border-collapse: collapse;
  text-align: center;
  width: 100%; /* Ensure table takes full width on mobile */
}

.city-league-sites-table-row-title-hiring th {
  color: white;
  font-size: 20px;
  background-color: teal;
}

.city-league-table{
  background: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12) !important;
   color:white !important
}

.city-league-sites-table-row th {
  background: gold;
  border-bottom: 6px solid black;
  font-weight: bolder;
  padding: 8px 10px;
}

.city-league-sites-table tbody td {
  /* padding: 8px; */
  height: 45px;
  border-bottom: 6px solid black;
  background-color: gold;
  font-weight: bold;
}

.city-league-sites-table-col-sl {
  text-align: left;
  background: rgb(94, 94, 243) !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
}

.city-league-sites-table-col-sl-title{
  display:flex;
  align-items:center;
  justify-content:center;
  margin:2px;
}

.city-image-container {
  object-fit: cover 1;
}

.city-image {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.data-cell {
  background: rgb(140 140 140) !important;
  color: white;
}

.data-total-cell {
  background: white !important;
  color: black;
  font-weight: 900 !important;
  font-size: 14px !important;
}

.data-cell-grey {
  background: #5a5a5a !important;
  color: white !important;
}

.city-trend{
  margin-left: 5px !important;
}

.city-trend-image{
  width: 20px;
  height: 20px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .city-league-sites-table {
    margin:10px 0px
  }

  .city-trend{
    margin: 1px !important;
  }

  .city-trend-image{
    width: 10px;
    height: 10px;
  }
  
  .data-total-cell{
    font-size: 13px !important;
  }

  .city-league-sites-table-col-sl-title{
    align-items:center;
    justify-content:space-between;
    display: flex;
    font-size: 8px;
  }

  .td{
    font-size: 8px !important;
  }
  .city-league-sites-container {
    padding: 0px 1px; /* Add padding for better spacing */
  }

  .city-league-image {
    height: 70px ;
    width: 70px ;
  }

  .city-league-sites-title {
    font-size: 20px !important;
  }

  .city-league-sites-sub-title {
    font-size: 15px !important;
  }

  .city-league-sites-table-row-title th{
    font-size: 13px !important;
    font-weight: 700;
  }
  
  .city-league-sites-table-row th {
    font-size: 9px !important;
    padding: 5px 1px !important;
    font-weight: 900;
    height: 20px;
    /* border-bottom: 2px solid black; */
    
  }

  .city-league-sites-table tbody td {
    height: 45px;
    font-size: 11px;
    /* padding: 5px 0px !important; */
    border-bottom: 4px solid black;
    padding:"0px 1px"
    /* border-bottom: 0px solid black !important; */
  }

  .city-league-sites-table-col-sl {
    /* font-size: 12px !important; */
    /* display: flex; */
    padding: 1px;
    font-size: 10px !important;
  }

  .city-trend{
    margin-left: 5px !important;
  }

  .city-image {
    height: 25px;
    width: 25px;
    margin-right: 5px;
  }

  .city-league-sites-table-container {
    margin-left: 0px;
  }

  .data-cell {
   padding: 0px !important;
   font-size: 12px
  }
  
  .data-cell-grey {
    padding: 0px !important;
    font-size: 12px;
  }

}
