.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff; /* adjust as needed */
    z-index: 1000; /* ensure it's above other content */
    /* Add any other styles like height, padding, etc. */
  }

.feed-back-ev-logo-image{
    width: 100px;
    height: 40px;
    object-fit: contain;
    @media (max-width: 768px) {
        width: 80px;
        height: 50px;
      }
}

.feed-back-logo-image{
    width: 100px;
    height: 65px;
    object-fit: contain;
    @media (max-width: 768px) {
        width: 80px;
        height: 50px;
        margin-top: 5px;
      }
}

.feed-back-ev-logo-container{
    margin: auto;
}